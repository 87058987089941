import React from 'react';
import Clientslogo from '../Clientslogo';

const ProducersSection = () => {
  return (
    <div className="section fp-section fp-tabble section_one">
      <div className="slider_container">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-12" style={{ padding: '50px' }}>
              <Clientslogo />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProducersSection;
