import React from 'react';
import SplitText from 'react-pose-text';
import slider_img2 from '../../images/new-page/slider_img2.jpg';

import { useMediaQuery } from '@react-hook/media-query';

const DetailsSection = () => {
  const matches = useMediaQuery('only screen and (max-width: 600px)');
  const charPoses = {};
  return (
    <div className="section fp-section fp-tabble">
      <div className={!matches ? 'slider_container' : ''}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-7">
              <div className="slider_content slider_content_two">
                <h6>
                  <span className="br"></span>
                  <SplitText charPoses={charPoses}>Detalii</SplitText>
                </h6>
                <h2 className="bold">
                  <SplitText charPoses={charPoses}>
                    Perfecte pentru Crăciun și alte evenimente
                  </SplitText>
                  <span className="br"></span>
                </h2>
                <p>
                  Surprinde angajații companiei tale cu cutii cadou care conțin
                  produse de calitate de la producători locali. <br />
                  În același timp, prin acest cadou ajutați comunitatea de
                  producători locali din cadrul platformei{' '}
                  <span style={{ color: '#39a36f' }}>Micul Aprozar</span>.
                  <br />
                  Impreună suntem "mai aproape de producătorii locali"!
                </p>
                <a
                  href="/#contact"
                  className="see_btn"
                  data-text="See Projects"
                >
                  Contactează-ne pentru a cumpăra acum!
                  <span className="arrow">
                    <span className="line"></span>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-5">
              {!matches ? (
                <div className="slider_image_inner mt-5">
                  <span></span>
                  <img src={slider_img2} alt="Posibile Produse" />
                </div>
              ) : (
                <div className="mt-5">
                  <img src={slider_img2} alt="Posibile Produse" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DetailsSection;
