import React from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import TitleSection from './TitleSection';
import DetailsSection from './DetailsSection';
import ProductsSection from './ProductsSection';

import ProducersSection from './ProducersSection';
import ContactSection from './ContactSection';
import NavbarThree from '../Navbar/NavbarThree';
import jhonData from '../jhonData';
import './style.scss';

import '../../assets/css/animate.scss';

import line1 from '../../images/new-page/line1.png';
import line2 from '../../images/new-page/line2.png';
import line3 from '../../images/new-page/line3.png';
import topshape2 from '../../images/new-page/topshape2.png';
import shape1 from '../../images/new-page/shape1.png';
import shape2 from '../../images/new-page/shape2.png';
import bottomshape from '../../images/new-page/bottomshape.png';
import arrow from '../../images/new-page/arrow.png';
import shape3 from '../../images/new-page/shape3.png';
import { useMediaQuery, useMediaQueries } from '@react-hook/media-query';

const FullpageSlider = (fullpageProps) => {
  const matches = useMediaQuery('only screen and (max-width: 900px)');

  return (
    <div className="scroll_page">
      <NavbarThree />
      <ReactFullpage
        autoScrolling={matches ? false : true}
        licenseKey={'OPEN-SOURCE-GPLV3-LICENSE'}
        anchors={['cadou', 'detalii', 'produse', 'producatori', 'contact']}
        callbacks={['onLeave']}
        navigation={matches ? false : true}
        navigationPosition={'left'}
        scrollOverflow={matches ? false : true}
        arrowNavigation={false}
        render={({ state, fullpageApi }) => {
          return (
            <React.Fragment>
              <TitleSection smallText="Micul Aprozar" />
              <DetailsSection />
              <ProductsSection />
              <ProducersSection />
              <ContactSection jhonData={jhonData} />
            </React.Fragment>
          );
        }}
      />
      <div
        className="section_bg"
        style={{
          background: '#FCFAF1', //background gradient
          position: 'fixed',
          height: '100%',
          width: '100%',
          top: '0',
          left: '0',
          zIndex: '-1',
        }}
      ></div>
    </div>
  );
};

export default FullpageSlider;
