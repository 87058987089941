import React, { useEffect } from 'react';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';

import FullpageSlider from '../components/Creativecarousel/FullpageSlider';
import FullpageSliderMobile from '../components/Creativecarousel/FullpageSliderMobile';
import fullpageOptions from '../components/Creativecarousel/fullpageConfig';

import Layout from '../components/layout';
import Seo from '../components/seo';

import './index.scss';

import '../assets/elagent-icon/style.css';
import '../assets/etline-icon/style.css';
import '../assets/flaticon/flaticon.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/css/style.css';
import '../assets/css/responsive.css';
import 'popper.js';
import CookieContainer from '../components/CookieContainer';

import { useMediaQuery, useMediaQueries } from '@react-hook/media-query';

const IndexPage = () => {
  return (
    <Layout>
      <Seo title="" />

      <FullpageSlider
        // isFooterOpen={true}
        // hide={this.hide}
        // show={true}
        {...fullpageOptions}
      />

      <CookieContainer />
    </Layout>
  );
};

export default IndexPage;
