import React, { Component } from 'react';
import Clientsliders from 'react-slick';
import Sectiontitle from './Banner/Sectiontitle';

import c1 from '../images/1.png';
import c2 from '../images/2.png';
import c3 from '../images/3.png';
import c4 from '../images/4.png';
import c5 from '../images/2.png';
import c6 from '../images/3.png';

class Clientslogo extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 500,
      arrows: false,
      slidesToShow: 4,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 1024,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 600,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1,
          },
        },
      ],
    };
    return (
      <section className="clients_logo_area">
        <div className="container">
          <Sectiontitle
            Title="Producători parteneri"
            TitleP="Împreună cu partenerii noștri ajutăm dezvoltarea comunității producătorilor locali din România!"
          />
          <Clientsliders {...settings} className="clients_slider">
            <div className="item">
              <img className="prodImg" src={c1} alt="Bunătați Uscate" />
              <p style={{ fontWeight: 'bold' }}>Bunătați Uscate</p>
            </div>
            <div className="item">
              <img className="prodImg" src={c2} alt="Pomăria Bădacin" />
              <p style={{ fontWeight: 'bold' }}>Pomăria Bădacin</p>
            </div>
            <div className="item">
              <img className="prodImg" src={c3} alt="Pasta Pedroni" />
              <p style={{ fontWeight: 'bold' }}>Pasta Pedroni</p>
            </div>
            <div className="item">
              <img className="prodImg" src={c4} alt="Bee Happy" />
              <p style={{ fontWeight: 'bold' }}>Bee Happy</p>
            </div>
          </Clientsliders>
        </div>
      </section>
    );
  }
}
export default Clientslogo;
