import React, { Component } from 'react';
import ImagesLoaded from 'imagesloaded/imagesloaded';
import p1 from '../../images/portfolio/10.png';
import p2 from '../../images/portfolio/2.png';
import p3 from '../../images/portfolio/9.png';
import p4 from '../../images/portfolio/4.png';
import p9 from '../../images/portfolio/11.png';
import p10 from '../../images/portfolio/3.png';

// import Isotope from 'isotope-layout/js/isotope'; // This needs to be commented for build and uncommented for develop
class ItemGrid extends Component {
  state = {
    activeItem: '*',
  };

  componentDidMount() {
    var imgLoad = new ImagesLoaded('.grid');
    if (typeof window !== `undefined`) {
      // import Isotope API

      const Isotope = require('isotope-layout/js/isotope'); // This needs to be on for build

      imgLoad.on('progress', function (instance, image) {
        this.iso = new Isotope('.grid', {
          itemSelector: '.grid-item',
          layoutMode: 'masonry',
        });
      });
    }
  }
  onFilterChange = (newFilter) => {
    this.setState({ activeItem: newFilter });
    if (this.iso === undefined) {
      this.iso = new Isotope('.grid', {
        itemSelector: '.grid-item',
        layoutMode: 'masonry',
      });
    }

    // this.iso.arrange({ filter: newFilter });

    if (newFilter === '*') {
      this.iso.arrange({ filter: `*` });
    } else {
      this.iso.arrange({ filter: `.${newFilter}` });
    }
  };

  onActive = (v) => (v === this.state.activeItem ? 'active' : '');
  render() {
    return (
      <div>
        <div className="grid row">
          <div className="col-md-3 col-sm-6 col-xs-12 grid-item tech develop">
            <div
              className={
                !this.props.matches
                  ? 'portfolio imgAnimCar hover-style'
                  : 'portfolio hover-style'
              }
            >
              <span></span>
              <img src={p1} alt="Compot Piersici" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Compot Piersici</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 grid-item market web develop">
            <div
              className={
                !this.props.matches
                  ? 'portfolio imgAnimCar hover-style'
                  : 'portfolio hover-style'
              }
            >
              <span></span>
              <img src={p2} alt="Ceai Multifruct" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Ceai Multifruct</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item market develop">
            <div
              className={
                !this.props.matches
                  ? 'portfolio imgAnimCar hover-style'
                  : 'portfolio hover-style'
              }
            >
              <span></span>
              <img src={p9} alt="Tagliatele artizanale cu ou și spanac" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">
                    Tagliatele artizanale cu ou și spanac
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-6 col-sm-6 col-xs-12 grid-item web">
            <div
              className={
                !this.props.matches
                  ? 'portfolio imgAnimCar hover-style'
                  : 'portfolio hover-style'
              }
            >
              <span></span>
              <img src={p10} alt="Miere cremoasă cu zmeură" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Miere cremoasă cu zmeură</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 grid-item market web">
            <div
              className={
                !this.props.matches
                  ? 'portfolio imgAnimCar hover-style'
                  : 'portfolio hover-style'
              }
            >
              <span></span>
              <img src={p3} alt="Dulceață piersici" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Dulceață piersici</h6>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-3 col-sm-6 col-xs-12 grid-item develop">
            <span></span>
            <div
              className={
                !this.props.matches
                  ? 'portfolio imgAnimCar hover-style'
                  : 'portfolio hover-style'
              }
            >
              <span></span>
              <img src={p4} alt="Pulbere Zmerură" />
              <div className="item-img-overlay">
                <div className="overlay-info text-center">
                  <h6 className="sm-titl">Pulbere Zmerură</h6>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ItemGrid;
