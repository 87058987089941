import React from 'react';
import emailjs from 'emailjs-com';
import toastify from '../utils/toast';

class Form extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errors: {
        name: '',
        phone: '',
        email: '',
      },
      hasErrors: true,
    };
  }

  handleChange = (event) => {
    let name = event.target.name;
    let value = event.target.value;
    let errors = this.state.errors;
    let _hasErrors = false;

    switch (name) {
      case 'name':
        if (value.length === 0) {
          errors.name = 'Numele nu poate fi gol';
          _hasErrors = true;
        } else {
          errors.name = '';
        }
        break;
      case 'phone':
        if (value.length < 5) {
          errors.phone = 'Numarul de telefon introdus greșit';
          _hasErrors = true;
        } else {
          errors.phone = '';
        }
        break;
      case 'email':
        if (value.length < 5) {
          errors.email = 'Email introdus greșit';
          _hasErrors = true;
        } else {
          errors.email = '';
        }
        let appos = value.indexOf('@');
        let dots = value.lastIndexOf('.');

        if (appos < 1 || dots - appos < 2) {
          errors.email = 'Introdu un email valid';
        }

        break;

      default:
        break;
    }

    this.setState({ errors, [name]: value });
    this.setState({ hasErrors: _hasErrors });
  };

  submitHandler = (e) => {
    e.preventDefault();
    toastify.Warn('Mesajul se trimite...');
    emailjs
      .sendForm(
        'service_cadouri_ma_ses',
        'template_mail_cadouri_ma',
        e.target,
        'user_A8i1pP9Q8iXSGRZr9b7NU'
      )
      .then(
        (result) => {
          toastify.Success('Mesajul a fost trimis cu success!');
        },
        (error) => {
          console.log(error.text);
          toastify.Error('Oops a apărut o erroare. 😢');
        }
      );

    e.target.reset();
  };

  render() {
    const { errors } = this.state;
    return (
      <form
        onSubmit={this.submitHandler.bind(this)}
        id="cForm"
        className="form_class"
      >
        <div className="row">
          <div className="col-lg-12">
            <input
              type="text"
              id="name"
              name="name"
              className="form-control inputFormBorder"
              placeholder="Nume *"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-lg-6">
            <input
              type="email"
              className="form-control inputFormBorder"
              id="email"
              name="email"
              placeholder="Email *"
              onChange={this.handleChange}
            />
          </div>
          <div className="col-lg-6">
            <input
              type="text"
              className="form-control inputFormBorder"
              id="phone"
              name="phone"
              placeholder="Numar de telefon *"
              onChange={this.handleChange}
            />
          </div>
        </div>
        <textarea
          name="message"
          id="message"
          className="form-control inputFormBorder"
          rows="6"
          placeholder="Mesajul tău ..."
          onChange={this.handleChange}
        ></textarea>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            flexWrap: 'wrap',
          }}
        >
          {' '}
          {this.state.hasErrors ? (
            <button type="submit" disabled className="btn send_btn theme_btn">
              Trimite mesaj
            </button>
          ) : (
            <button type="submit" className="btn send_btn theme_btn">
              Trimite mesaj
            </button>
          )}
          <div class="break" style={{ flexBasis: '100%' }}></div>
          <p
            style={{
              color: '#C31F28',
              marginTop: '5px',
              height: '20px',
              textAlign: 'left',
              fontSize: '18px',
              fontWeight: 'bold',
              fontFamily: 'Montserrat, sans-serif',
            }}
          >
            {errors.name
              ? errors.name
              : errors.email
              ? errors.email
              : errors.phone
              ? errors.phone
              : ''}
          </p>
        </div>
      </form>
    );
  }
}

export default Form;
