export default {
  callbacks: ['onLeave'],
  // licenseKey: "OPEN-SOURCE-GPLV3-LICENSE",
  anchors: ['cadou', 'detalii', 'produse', 'producatori', 'contact'],
  navigation: true,
  navigationPosition: 'left',
  scrollOverflow: true,
  arrowNavigation: false,
  autoScroll: false,
};
