import React from 'react';
import CookieConsent from 'react-cookie-consent';

const CookieContainer = () => {
  return (
    <CookieConsent
      location="bottom"
      cookieName="Cookies Consent"
      expires={999}
      overlay
      buttonText="Accept"
      buttonStyle={{
        marginLeft: 'auto',
        marginRight: '30px',
        backgroundColor: '#39a36f',
        color: 'white',
        border: '1px solid transparent',
        width: '100px',
        borderRadius: '10px',
      }}
    >
      Folosim cookies pentru a îmbunătății continuu experența de utilizare.
      Apăsând Accept sau navigând pe acest website, ești de acord cu politica
      noastră de cookies.
      <a href="/tos" target="_blank" style={{ color: '#39a36f' }}>
        {' Politica de Cookies'}
      </a>
    </CookieConsent>
  );
};

export default CookieContainer;
