import React from 'react';
import SplitText from 'react-pose-text';
import slider_img1 from '../../images/colaj_produse.png';

import { useMediaQuery } from '@react-hook/media-query';

const TitleSection = () => {
  const matches = useMediaQuery('only screen and (max-width: 600px)');
  const charPoses = {};
  return (
    <div className="section fp-section fp-tabble section_one">
      <div className={!matches ? 'slider_container' : ''}>
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <div className="slider_content">
                <h6>
                  <span className="br"></span>
                  <SplitText charPoses={charPoses}>Micul Aprozar</SplitText>
                </h6>
                <h1>
                  <SplitText charPoses={charPoses}>
                    Cutii Cadou Pentru Angajații Companiei
                  </SplitText>
                </h1>
                <a
                  href="/#detalii"
                  className="see_btn"
                  data-text="See Projects"
                >
                  Vezi mai multe
                  <span className="arrow">
                    <span className="line"></span>
                  </span>
                </a>
              </div>
            </div>
            <div className="col-lg-7">
              {!matches ? (
                <div
                  className="slider_image_inner"
                  style={{ boxShadow: '0px 0px 0px 0px' }}
                >
                  <span></span>
                  <img src={slider_img1} alt="Posibile Produse 1" />
                </div>
              ) : (
                <div>
                  <img src={slider_img1} alt="Posibile Produse 1" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TitleSection;
