import React from 'react';
import SplitText from 'react-pose-text';
import ItemGrid from '../Portfolio/ItemGrid';

import { useMediaQuery } from '@react-hook/media-query';

const ProductsSection = () => {
  const matches = useMediaQuery('only screen and (max-width: 900px)');
  const charPoses = {};
  const isBrowser = typeof window !== 'undefined';
  return (
    <div className="section fp-section fp-tabble section_one mt-5">
      <div className="">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
              {' '}
              <div className="slider_content">
                <h6>
                  <span className="br"></span>
                  <SplitText charPoses={charPoses}>Produse</SplitText>
                </h6>
                <h3
                  className="productSectionTitle"
                  style={{ color: '#454d51' }}
                >
                  <SplitText charPoses={charPoses}>
                    Cutiile cadou conțin produse speciale de la producători
                    locali.
                  </SplitText>
                </h3>
                <p>
                  Cutiile se pot configura cu produse din{' '}
                  <span style={{ color: '#39a36f' }}>Micul Aprozar</span>.
                  <br />
                  Îți oferim:{' '}
                  <span style={{ color: '#39a36f' }}>
                    paste artizanale
                  </span>,{' '}
                  <span style={{ color: '#39a36f' }}> dulceță ecologică</span>,{' '}
                  <span style={{ color: '#39a36f' }}>
                    fructe și legume deshidratate
                  </span>
                  , <span style={{ color: '#39a36f' }}>ceaiuri naturale</span>,{' '}
                  <span style={{ color: '#39a36f' }}>miere cremă</span> și multe
                  altele, începând de la 50 RON / cutie.
                </p>
                {/* <a
                  href="/#detalii"
                  className="see_btn"
                  data-text="See Projects"
                >
                  Vezi mai multe
                  <span className="arrow">
                    <span className="line"></span>
                  </span>
                </a> */}
              </div>
            </div>
            <div className="col-lg-6">
              {isBrowser && <ItemGrid matches={matches} />}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductsSection;
