import React from 'react';
import Reveal from 'react-reveal';
import Form from '../Form';

import { ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';

const ContactSection = (props) => {
  let jhonData = props.jhonData;
  return (
    <>
      <ToastContainer
        position="bottom-center"
        autoClose={5000}
        hideProgressBar
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable={false}
        pauseOnHover
        theme="colored"
      />
      <div className="section fp-section fp-tabble section_one contact-area">
        <div className="slider_container">
          <div className="container">
            <div className="row row-reverse">
              {jhonData.contact &&
                jhonData.contact.map((item) => {
                  return (
                    <React.Fragment key={item.id}>
                      <div className="col-md-5">
                        <Reveal effect="fadeInLeft" duration={500}>
                          <div className="contact_info">
                            <h4>{item.title}</h4>
                            <ul className="nav">
                              {item.menuItems.map((info) => {
                                return (
                                  <li className="item" key={info.id}>
                                    {info.type === 'email' && (
                                      <div className="media">
                                        <i className={info.icon}></i>
                                        <div className="media-body">
                                          <a
                                            href={`mailto:${info.email}`}
                                            style={{
                                              fontFamily:
                                                'Montserrat, sans-serif',
                                              color: '#39a36f',
                                            }}
                                          >
                                            {info.text}
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                    {info.type === 'phone' && (
                                      <div className="media">
                                        <i className={info.icon}></i>
                                        <div className="media-body">
                                          <a
                                            href={`tel:${info.phone}`}
                                            style={{
                                              fontFamily:
                                                'Montserrat, sans-serif',
                                              color: '#39a36f',
                                            }}
                                          >
                                            {info.text}
                                          </a>
                                        </div>
                                      </div>
                                    )}
                                  </li>
                                );
                              })}
                            </ul>
                          </div>
                        </Reveal>
                      </div>
                    </React.Fragment>
                  );
                })}
              <div className="col-md-7">
                <Reveal effect="fadeInRight" duration={800}>
                  <div className="input_form">
                    <h4>{jhonData.contacttitle}</h4>
                    <Form />
                  </div>
                </Reveal>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactSection;
